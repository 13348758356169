import './App.css';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/Home';
import SidebarNavItem from './components/SidebarNavItem';
import Campaigns from './pages/Campaigns/Campaigns';
import Intel from './pages/Intel/Intel';
import { AxiosInstanceProvider } from './contexts/AxiosContext';
import { CampaignContextProvider } from './contexts/CamapaignContext';

function App() {
  return (
    <AxiosInstanceProvider>
      <CampaignContextProvider>
        <BrowserRouter>
          <div id="wrapper">

            <div id="sidebar">
              <img alt="wing logo" src="/132nd_Logo_new_trans.svg" style={{
                width: "63.5%",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "20px",
              }} />
                <SidebarNavItem end to="/" icon="oi-home">Dashboard</SidebarNavItem>
                <SidebarNavItem to="/campaigns" icon="oi-list">Add Intelligence</SidebarNavItem>
                <SidebarNavItem to="/intelligence" icon="oi-magnifying-glass">View Intelligence</SidebarNavItem>
            </div>

            <div id="content">
            <Routes>
              <Route path="/intelligence">
                <Route path=":campaign_id" element={<Intel/>} />
                <Route path=":campaign_id/:event_id" element={<Intel/>} />
                <Route path=""  element={<Intel/>} />
              </Route>  
              <Route path="/campaigns">
                <Route path=":campaign_id" element={<Campaigns/>} />
                <Route path=""  element={<Campaigns/>} />
              </Route>
              <Route path="/" element={<Home/>} />
            </Routes>
            </div>
          </div>
        </BrowserRouter>
      </CampaignContextProvider>
    </AxiosInstanceProvider>
  );
}

export default App;
