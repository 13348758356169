import proj4 from "proj4";
import { Geodesic } from "geographiclib";


// Pad "n" to given width with 'z'. In the case of a number: pad the
// characteristic, and mantissa to desired precision
//   e.g: (54.24, 3, null, 4) => 054.2400
export function pad(n, width, z, precision) {

    z = z || '0';
    precision = precision || 0;
  
    var nn = parseFloat(n)
    if (!isNaN(nn)) {
  
      var nni = Math.floor(nn)
  
      // Turn our int into a string
      var nns = nni + '';
  
      // pad NN to width
      nns = nns.length >= width ? nns : new Array(width - nns.length + 1).join(z) + nns;
  
      // Then append any remainder, merging back to n
      if (precision > 0) {
        n = nns + ((nn - nni).toFixed(precision) + '').substr(1)
      } else {
        n = nns
      }
    }
  
    // continue pad as before
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
  

export function inputToUpper(evt) {
    evt.target.value = evt.target.value.toUpperCase();
}

export function coordinateDD2DDM(dd, lat, precision) {

    lat = lat !== false;
    precision = precision || 3;

    if (isNaN(dd) || dd === "") {
        return "invalid coordinate";
    }
  
    let axis = lat ?
        dd >= 0 ? "N " : "S ":
        dd >= 0 ? "E " : "W ";
        
    let work = Math.abs(dd)
    let deg = Math.floor(work);
    let min = ((work - deg) * 60).toFixed(precision);
    if (min === "60.000") {
        min = 0;
        deg += 1;
    }

    return axis + pad(deg, lat ? 2 : 3, "0") + " " + pad(min, 2, 0, precision);
}

export function coordinateDisplayFormat(coordinate) {
    if (coordinate instanceof Array) {
        return [coordinateDD2DDM(coordinate[0]), coordinateDD2DDM(coordinate[1], false), coordinate[3]]; 
    } else {
        return [coordinateDD2DDM(coordinate.lat), coordinateDD2DDM(coordinate.lon, false), coordinate.alt];
    }
    
}


export function coordinatesFromString(str, bullseye) {

    // Parse provided string to work out a lat/lon value and null on error
    str = (str || "").toUpperCase();

    // BULLSEYE Position
    if (bullseye) {
        let be_arr = new RegExp(/^\s*(?:BE?)(?:\s*([0-9]+)[-;,/ ]([0-9]+))?\s*$/).exec(str);
        if (be_arr) {
            if (be_arr[1]) {
                let point = Geodesic.WGS84.Direct(bullseye.lat, bullseye.lon, parseFloat(be_arr[1]), parseFloat(be_arr[2])*1852);
                return [point.lat2, point.lon2]
            }
            return [bullseye.lat, bullseye.lon]
        }
    }
    
    // DD
    // FSE / GCE combined
    // FSE:     Lat: 10.25N, Long: 67.6498W
    // Google:  23.24124, 112.42424
    // Additionally:  23.24124S, 112.42424W
    // Additionally:  S23.24124S, W112.42424
    // Additionally:  23, 112W

    let dd_arr = new RegExp([
        /^\s*/,
        /(?:LAT(?:\s|:))?\s*/,                  // We optional prefix with lat (mainly for copy paste abiltiy)
            /([NS])?\s*/,                         // Optional NS prefix
            /(-?[0-9]{1,2}(?:[.,][0-9]*)?)\s*/,  // DD (optional mantissa by comma or decimal point)
            /([NS])?\s*/,                         // Optional NS suffix
        /(?:,|\s)\s*/,                          // Separator between Nothing / Easting
        /(?:LONG?(?:\s|:))?\s*/,                // Repeat for Long
            /([EW])?\s*/,
            /(-?[0-9]{1,3}(?:[.,][0-9]*)?)\s*/,
            /([EW])?\s*/,
        /$/
    ].map(function(r) {return r.source}).join('')).exec(str);
  
    if (dd_arr) {

        // Convert , number separator to . for parseFloat to be happy
        let lat = parseFloat(dd_arr[2].replaceAll(',', '.'))
        let lon = parseFloat(dd_arr[5].replaceAll(',', '.'))
    
        // Handle Northing prefix / suffix
        if (dd_arr[1]) {
          if (dd_arr[1] === 'S') { lat *= -1; }
        } else if (dd_arr[3] && dd_arr[3] === 'S') {
          lat *= -1;
        }
    
        if (dd_arr[4]) {
          if (dd_arr[4] === 'W') { lon *= -1; }
        } else if (dd_arr[6] && dd_arr[6] === 'W') {
          lon *= -1;
        }
    
        // If we have a latitude < 0 or > 90, then we wrap
        // if we have a longitude < 180 > 180, we wrap
        return [lat, lon];
    }

    // MGRS
    let mgrs_arr = new RegExp([
        /^\s*/,                                                        // Allow start padding
        /([0-6]?[0-9])\s*([ABCDEFGHJKLMNPQRSTYVWX])\s*/,               // Grid Zone: C-X omiiting I and O (not including poles / UPS)
        /([ABCDEFGHJKLMNPQRSTUVWXYZ])\s*([ABCDEFGHJKLMNPQRSTUV])\s*/,  // 100km square, column and row, [A-Z, A-V] excluding I and O
        /([0-9 ]+)/,                                                   // location reference, this needs to be two groups of eql. length
                                                                       // But we'll handle that in the test function
        /$/
    ].map(function(r) {return r.source}).join('')).exec(str);

    if(mgrs_arr) {

        let mgrs = (() => {
            // Simple checks before sending it of to proj4

            let grid_zone = parseInt(mgrs_arr[0]);
            if (isNaN(grid_zone) || grid_zone > 60 || grid_zone === 0) { return null; }

            // ensure our numbers are multiple of 2
            let locref = mgrs_arr[5].replaceAll(' ', '');
            if (locref.length % 2 !== 0) { return null; }

            // Finally we're good to go, just merge all our capture groups except 0
            mgrs_arr.shift();
            return proj4.mgrs.inverse(mgrs_arr.join('').replaceAll(' ', ''));

        })();

        if (mgrs) {
            return [mgrs[1], mgrs[0]];
        }
    }

    // DMS
    let dms_arr = new RegExp([
        /^\s*/,                                           // Allow start padding
        /([NS])?\s*/,                                     // We allow N at the start, or end, so it's optional
        /(-?[0-9]+)/,                                     // Degrees is always required
        /(?:(?:\xB0|\s)([0-9]+)\s*)?/,                    // Minutes are optional, but if present, need to be preceded
                                                          // by a space or degree sign, integer to differentiate between DMS / DDM
            /(?:(?:'|\s)\s*([0-9]+(?:\.[0-9]+)?)"?)?\s*/, // Seconds are also optional, but if present, need to be preceeded by a
                                                          // ' or space from minutes and o ptioanll end in a "
            /([NS])?\s*/,                                 // Allow NS at end
        /(?:([EW])|\s)\s*/,                              // Repeat for East West, with required space 
        /(-?[0-9]+)/,
        /(?:(?:\xB0|\s)([0-9]+))?\s*/,
            /(?:(?:'|\s)\s*([0-9]+(?:\.[0-9]+)?)"?)?\s*/,
            /([EW])?\s*/,
        /$/
    ].map(function(r) {return r.source}).join('')).exec(str);


    if (dms_arr) {
        let dms_return = (() => {
            var lat = parseInt(dms_arr[2]);
            if (dms_arr[3]) { lat += parseInt(dms_arr[3])/60; }
            if (dms_arr[4]) {
                // If we have seconds, but no minutes, then it's ambiguous, so don't do
                // it and just bail and let it fall through especially in the case we
                // might see
                //
                // N 12 24.24 E 12 24 12.142
                //
                // Which is it 12.24 seconds, or is it decimal minutes ?
                if(!dms_arr[3]) return null;
                lat += parseFloat(dms_arr[4])/3600;
            }

            if (dms_arr[1] && dms_arr[1] === 'S') { lat *= -1; }
            else if (dms_arr[5] && dms_arr[5] === 'S') { lat *= -1; }

            var lon = parseInt(dms_arr[7]);
            if (dms_arr[8]) { lon += parseInt(dms_arr[8])/60; }
            if (dms_arr[9]) { 
                // As above
                if(!dms_arr[8]) return null;
                lon += parseFloat(dms_arr[9])/3600; 
            }
            if (dms_arr[6] && dms_arr[6] === 'W') { lon *= -1; }
            else if (dms_arr[10] && dms_arr[10] === 'W') { lon *= -1; }

            return [lat, lon]
        })();

        if (dms_return) return dms_return
    }
    

    // DDM, This only needs to cater for full format DDM as leaving of the decimal makes it matched by DMS above
    var ddm_arr = new RegExp([
        /^\s*/,                            // Allow start padding
        /([NS])?\s*/,                      // We allow N at the start, or end, so it's optional
        /(-?[0-9]+)(?:\xB0|\s)\s*/,        // Degrees is always required and must end in a space to be valid
        /([0-9]+(?:\.[0-9]+)?)'?\s*/,      // Minutes
        /([NS])?\s*/,                      // Cater for NS at end of string
        /([EW])?\s*/,
        /(-?[0-9]+)(?:\xB0|\s)\s*/,
        /([0-9]+(?:\.[0-9]+)?)'?\s*/,
        /([EW])?\s*/,
        /$/
    ].map(function(r) {return r.source}).join('')).exec(str);

    if (ddm_arr) {
        let lat = parseInt(ddm_arr[2]) + parseFloat(ddm_arr[3])/60;
        if (ddm_arr[1] && ddm_arr[1] === 'S') { lat *= -1; }
        else if (ddm_arr[4] && ddm_arr[4] === 'S') { lat *= -1; }

        let lon = parseInt(ddm_arr[6]) + parseFloat(ddm_arr[7])/60;
        if (ddm_arr[5] && ddm_arr[5] === 'W') { lon *= -1; }
        else if (ddm_arr[8] && ddm_arr[8] === 'W') { lon *= -1; }

        return [lat, lon];
    }

    return null
}

// Helper to get the number of seconds from a "HH:mm" string, used for
// calculating TOT
export function get_seconds_from_time(time) {
    if (time === "") {
        return 0
    }

    // Regex is (H:)?M(:S)?, this also covers the 4 numbers case below
    var res = time.match(/^(?:([0-9]+):)?([0-9]+)?(?::([0-9]{1,2}))?$/);
    if (!res) {
        return 0
    }

    // If we have 4 numbers thats < 2400 then we can treat 1920 as 19:20
    // 4 numbers is ensured by the previous regex above
    if (time.length === 4) {
        var inttime = parseInt(time);
        if (!isNaN(inttime) && inttime <= 2400 && inttime >= 0) {
            res = [time, time.substring(0,2), time.substring(2,4)]
        }
    }

    var secs = 0;
    if (res[1]) { secs += parseInt(res[1])*3600; }
    if (res[2]) { secs += parseInt(res[2]*60); }
    if (res[3]) { secs += parseInt(res[3]); }
    return secs;
}

// Helper to get the "HH:mm:ss" string from number of seconds since 00:00 as the
// format used in DCS mission start
export function get_time_from_seconds(seconds, duration, show_seconds=false) {

    duration = duration || false;

    if (seconds === 0) {
        return "";
    }

    let work = seconds;
    let hours = Math.floor(seconds / 3600);

    work -= hours*3600;

    // If we are formatting a time we just want clock hours
    if (!duration)  {
        hours %= 24;
    }

    let minutes = Math.floor(work / 60);
    work -= minutes * 60;

    if (show_seconds) {
        return pad(hours, 2)+":"+pad(minutes,2)+":"+pad(work, 2);
    }
    return pad(hours, 2)+":"+pad(minutes,2);
}

