

import { useEffect, useMemo, useState } from "react";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useSortBy, useTable } from "react-table";
import IntelEditModal from "../../components/IntelEditModal";
import { config } from "../../Constants";
import {coordinateDisplayFormat} from "../../utils"

function IntelEditButton({
    cell
}) {

    return (
        <OverlayTrigger
            overlay={
                <Tooltip>
                    Edit Intel
                </Tooltip>
            } 
            placement="left">
            <Button onClick={(e) => { e.target.blur(); cell.column.props.rowClickedHandler(cell.row) }} variant="secondary" size="sm" style={{paddingTop:"0px", paddingLeft: "6px", paddingRight: "6px", marginRight:"10px", paddingBottom: "2px"}}>
                <span style={{color: "#eee", fontSize: "9px", margin: "0"}} className="oi oi-pencil"></span>
            </Button>
        </OverlayTrigger>
    )
}

export default function IntelTable({
    campaign_data: original_campaign_data,
    campaign_event,
}) {

    // Store original so we can edit it, and refresh on reload etc.
    const [campaign_data, set_campaign_data] = useState(original_campaign_data);

    useEffect(() => {
        set_campaign_data(original_campaign_data)
    }, [original_campaign_data, set_campaign_data])


    const [updateIntel, setUpdateIntel] = useState({
        'show': false,
        'intel': null,
    })

    const rowClickedHandler = (row) => {
        setUpdateIntel({
            'show': true,
            'intel': row.original,
            'campaign': campaign_data,
            'event': row.original.event,
            'row': row,
        })
    }

    const handleAddIntelClose = (res) => {

        // update our campaign_data with the new row to re-draw the table
        // this is god awful 

        if (res) {
            let new_campaign_data = {...campaign_data}
            // Find our event 
            for (const event of Object.values(new_campaign_data.events)) {
                if (event.id !== updateIntel.event.id) continue;
                // find the index of the intel
                let idx = event.intel.map(e => e.id).indexOf(res.data.id)
                if (idx !== -1) {
                    event.intel[idx] = res.data;
                }
            }
            set_campaign_data(new_campaign_data)
        }

        setUpdateIntel({'show': false})
    }

    const locationFormatter = (obj) => {
        let fmt = coordinateDisplayFormat(obj)
        return `${fmt[0]} ${fmt[1]}`;
    }
    
    const columns = useMemo(() => [
        {
            'accessor': 'ref',
            'Header': 'Intel Ref',
            'width': '85px',
            'sortable': true,
        },
        {
            'Header': 'Reporting Pilot',
            'accessor': 'pilot',
            'width': '130px',
        },
        {
            'Header': 'Reporting Callsign',
            'accessor': 'callsign',
            'width': '120px',
        },
        {
            'Header': 'Report Made (Game Local)',
            'accessor': 'report_time',
            'width': '150px',
        },
        {
            'Header': 'Report Type',
            'accessor': 'report_type',
            'width': '100px',
        },
        {
            'id': 'observed_from',
            'Header': 'Observed From',
            'width': '220px',
            'Cell': (({row: {original}}) => {
                return (
                    <>
                    {(original?.observed_from_loc?.lat 
                        ? locationFormatter(original.observed_from_loc)
                        : original.observed_from)}
                    {(original?.observed_from_loc?.alt 
                        ? <><br/>{original?.observed_from_loc?.alt}ft</>
                        : "")}
                    </>
                )
            })    
        },
        {
            'id': 'observed_target',
            'width': '220px',
            'Header': 'Observed Location',
            'Cell': ({row: {original}}) => {
                return (
                    <>
                    {(original?.observed_target_loc?.lat 
                        ? locationFormatter(original.observed_target_loc)
                        : original.observed_target)}
                    {(original?.observed_target_loc?.alt 
                        ? <><br/>{original?.observed_target_loc?.alt}ft</>
                        : "")}
                    </>
                )
            }
        },
        {
            'Header': 'CF Export',
            'accessor': 'exported',
            'width': '115px',
            'sortable': true,
        },
        {
            'Header': 'Report Content',
            'accessor': 'content',
            'width': null,
            'minWidth': '250px',
        },
        {
            'id': 'buttons',
            'Cell': IntelEditButton,
            'width': '40px',
            'props': {
                rowClickedHandler: rowClickedHandler,
            }
        },
    ],  []);

    const complete_table = useMemo(() => {
        let rows = [];
        (campaign_data.events || []).forEach((event) => {
            event.intel.forEach((intel) => {
                // store our event id for filtering
                intel['event'] = event
                intel['event_id'] = event.id
                intel['ref'] = `D${event.ato_day}.${event.ato_event}-${intel.event_local_id.toString()}`
                intel['exported'] = intel.observed_target_loc?.lat && intel.observed_target_loc?.lon ? "Yes" : "No"
                rows.push(intel);
            })
        });
        return rows;
    }, [campaign_data])

    const table_data = useMemo(() => {
        if (!campaign_event) return complete_table;
        return complete_table.filter((x) => x.event_id === campaign_event);
    }, [complete_table, campaign_event])
    
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
            columns,
            data: table_data,
        },
        useSortBy)

    let elem = null;
    
    if (rows.length > 0) {
        elem = (
            <>
            <Table striped {...getTableProps({style: {}})}>
                <thead>
                    {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            //, ...{style: {minWidth: column.minWidth || column.width, width: column.width}})
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{minWidth: column.minWidth || column.width, width: column.width}} >
                            {column.render('Header')}
                            <span>
                                {column.isSorted ? (column.isSortedDesc 
                                    ? <span style={{paddingLeft: "5px"}} className="oi oi-chevron-bottom" /> 
                                    : <span style={{paddingLeft: "5px"}} className="oi oi-chevron-top" /> ) : ""}
                            </span>
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} style={{backgroundColor: row.original.exported === "No" ? "#ff00000c" : null}}>
                            {row.cells.map(cell => {
                                return (
                                    <td {...cell.getCellProps()} style={{whiteSpace: 'pre-line', wordBreak: 'break-all', verticalAlign: "middle"}}>
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            {campaign_event &&
            <Button className="mb-3" href={`${config.url.API_BASE}/campaigns/${campaign_data.id}/events/${campaign_event}/intel?format=cf`}>Export to Combat Flite</Button>}
            {updateIntel.show && <IntelEditModal onClose={handleAddIntelClose} {...updateIntel} />}
            </>
        )
    } else {
        elem = `No intel for this ${campaign_event ? "event" : "campaign"}`
    }

    return (
        <>
            {elem}
        </>
    )
}