import { useCallback, useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useTable } from "react-table";
import IntelEditModal from "../../components/IntelEditModal";
import { coordinateDisplayFormat } from "../../utils";

function CampainEventAddIntelButton({
    cell
}) {

    const clickHandler = () => {
        cell.column.getProps.clickHandler(cell)
    }

    return (
        <Button variant="secondary" size="sm" style={{width:"100%"}} onClick={clickHandler}>Add Intel</Button>
    )
}

export default function CampaignEventTable({
    campaign,
}) {

    const [addIntel, setAddIntel] = useState({
        'show': false
    })

    const showAddIntelDialog = useCallback((campaign, event) => {
        setAddIntel({
            'campaign': campaign,
            'event': event,
            'show': true,
        });
    }, [setAddIntel]);

    const handleAddIntelClose = () => {
        setAddIntel({'show': false})
    }
    
    const columns = useMemo(() => [
        {
            'id': 'ref',
            'Header': 'Event Ref',
            'Cell': ({row}) => `D${row.original.ato_day}.${row.original.ato_event}`,
        },
        {
            'Header': 'Event Date',
            'accessor': 'date',
        },
        {
            'Header': 'Event Bullseye',
            'accessor': 'bullseye',
            'Cell': ({row}) => coordinateDisplayFormat(row.original.bullseye_loc).join(" "),
        },
        {
            'id': 'buttons',
            'Cell': CampainEventAddIntelButton,
            'getProps': {
                'clickHandler': (cell) => { showAddIntelDialog(campaign, cell.row.original); },
                'campaign': campaign,
            }
        },
    ],  [campaign, showAddIntelDialog]);

    function getColumnStyle(cc) {
        let retval = {}
        let widths = {
            'ref': '120px',
            'buttons': '120px',
        }

        if (widths[cc.id]) {
            retval['width'] = widths[cc.id]
        } 

        return retval;
    }
    
    const { getTableProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: campaign?.events || [],
    })

    let elem = null;
    
    if (rows.length > 0) {
        elem = (
            <Table striped {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()} >
                            {column.render('Header')}
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <tbody>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} onClick={() => {showAddIntelDialog(campaign, row.original)}}>
                            {row.cells.map(cell => {
                                return (
                                    <td {...cell.getCellProps()} style={{verticalAlign: "middle", ...getColumnStyle(cell.column)}}>
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>)
    } else {
        elem = "No events configured for this campaign"
    }

    return (
        <>
            <hr/>
            <h4>Events for {campaign?.tag}: {campaign?.name}</h4>
            {elem}
            {addIntel && <IntelEditModal onClose={handleAddIntelClose} {...addIntel} />}
        </>
    )
}