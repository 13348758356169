import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { AxiosContext } from "./AxiosContext";

export const CampaignContext = createContext(null);

export const CampaignContextProvider = ({
    children
}) => {

    const [campaigns, setCampaigns] = useState([]);
    const axiosContext = useContext(AxiosContext);

    const refreshCampaigns = useCallback(() => {
        axiosContext.get('/campaigns').then(res => {
            if (!res?.data) return;
            setCampaigns(res.data);
        });
    }, [axiosContext, setCampaigns]);

    useEffect(() => {
        refreshCampaigns();
    }, [refreshCampaigns]);
    

    return (
        <CampaignContext.Provider
            value={{
                "campaigns": campaigns,
                "refresh": refreshCampaigns
            }}
        >
            {children}
        </CampaignContext.Provider>
    )

};