import { useCallback, useContext, useState } from "react";
import { Button, Col, Fade, Form, Modal, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { AxiosContext } from "../contexts/AxiosContext";
import CoordinateInput from "./CoordinateInput";
import TimeInput from "./TimeInput";

function IntelEditModal ({
    show,
    campaign,
    event,
    intel,
    onClose,
}) {

    const axiosContext = useContext(AxiosContext);

    ///////////////////////////////////////////////////////////////////////////
    // Add Intel handling
    ///////////////////////////////////////////////////////////////////////////

    // Intial intel needs a bit of love to convert from alt as part of loc to external so it shows nicely in the form
    if (intel) {
        if (intel?.observed_from_loc?.alt) { intel.observed_from_alt = intel.observed_from_loc.alt }
        if (intel?.observed_target_loc?.alt) { intel.observed_target_alt = intel.observed_target_loc.alt }
    }
    
    const [showIntelEntry, setShowIntelEntry] = useState(true);
    const [intelFormState, setIntelFormState] = useState({
        'report_type': 'Unknown',
        ...(intel || {}),
    });


    const updateIntelFormState = useCallback((kv) => {
        let new_data = {...intelFormState, ...kv};
        setIntelFormState(new_data);
    }, [intelFormState, setIntelFormState]);

    const updateIntelFormEntry = useCallback((evt) => {
        let element = evt.target;
        updateIntelFormState({[element.name]: element.value})
    }, [updateIntelFormState]);

    function handleIntelEntryExited(evt) {
        // Reset our state just to have pilot and callsign
        let new_state = {
            'pilot': intelFormState.pilot || "",
            'callsign': intelFormState.callsign || "",
            'report_time': intelFormState.report_time || "",
            'report_type': intelFormState.report_type || "",
        }
        setIntelFormState(new_state);
        setShowIntelEntry(true);
    }

    function handleAddIntelSave(evt) {
        // Never want normal
        evt.preventDefault();

        // Now we can play with our submit button saying submitted
        const form = evt.currentTarget;
        if (form.checkValidity() === false) {
            evt.stopPropagation();
            return
        }

        // Now we are either adding new intel, or patching existing intel based on intel
        let dispatch = {...intelFormState};

        // Add our event id from page
        dispatch['event_id'] = event.id;

        // remove event data if it exists
        delete(dispatch['event']);

        // Move altitude under location
        ['observed_from', 'observed_target'].forEach((x) => {
            let alt = parseInt(dispatch[x+"_alt"])
            let key = x+"_loc";
            if (dispatch[key]) {
                dispatch[key].alt = alt || 0
            } else if (alt) {
                dispatch[key] = {'alt': alt}
            }
        });

        if (intel) {
            // Compare dispatch against original intel, to see if nay changes are made, if not we just close the modal
            let patch = {};
            for (const [k, v] of Object.entries(dispatch)) {

                // If it's an object like lat,lon,alt, then we need to ensure it's all there as the update could move it from a 
                // valid LL to invalid, so make sure they're right

                if (v instanceof Object) {
                    let include = (() => {

                        let a_keys = Object.keys(v);
                        let b_keys = Object.keys(intel?.[k] || []);
                        
                        // If keys don't match, then we're wrong
                        let difference = a_keys
                            .filter(x => b_keys.includes(x))
                            .concat(b_keys.filter(x => !a_keys.includes(x)));

                        if (difference.length > 0) {
                            return true;
                        }

                        for (const [k2, v2] of Object.entries(v)) {
                            if (!intel[k] || intel[k][k2] !== v2) {
                                return true;
                            }
                        }
                        
                        return false;

                    })();

                    if (include) {
                        patch[k] = v
                    }
                } else if (v !== intel[k]) {
                    patch[k] = v
                }
            }

            // nothing to patch, just close
            if (Object.keys(patch).length === 0) {
                onClose();
                return
            }
            
            axiosContext.patch(
                `/campaigns/${campaign.id}/events/${event.id}/intel/${intel.id}`,
                patch,
            ).then((res) => {
                onClose(res);
            });

            return;
        }

        // Submit our Intel
        axiosContext.post(
            `/campaigns/${campaign.id}/events/${event.id}/intel`,
            dispatch,
        ).then((res) => {
            if (!res) return;
            // Reset state
            setShowIntelEntry(false);
        });
    }

    return (

        <Modal backdrop="static" show={show} onHide={() => { onClose(); } }>
            <Modal.Header closeButton>
                <Modal.Title>
                    {intel ? 
                        <>Update:    {intel.ref}</> :
                        <>Add Intel to {campaign?.tag}: D{event?.ato_day}.{event?.ato_event}</>
                    } 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="addEventForm" action='' onSubmit={handleAddIntelSave}>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Pilot Name</Form.Label>
                            <Form.Control onChange={updateIntelFormEntry} required name="pilot" value={intelFormState.pilot || ""} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Callsign</Form.Label>
                            <Form.Control onChange={updateIntelFormEntry} required name="callsign" value={intelFormState.callsign || ""} />
                        </Form.Group>
                    </Row>
                    <hr/>
                    <div style={{overflow: "hidden"}}>
                        <Fade timeout={500} in={showIntelEntry} onExited={handleIntelEntryExited} >
                        <div style={{transition: 'opacity 0.5s'}}>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Report Type</Form.Label>
                                <Form.Select name="report_type" required onChange={updateIntelFormEntry} value={intelFormState.report_type || "Unknown"}>
                                    <option value="Unknown">Unknown</option>
                                    <option value="BDA">BDA</option>
                                    <option value="Intelligence">Intelligence</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>In-game Local time</Form.Label>
                                <TimeInput onChange={updateIntelFormEntry} required name="report_time" value={intelFormState.report_time || ""} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label style={{"width": "100%"}}>Observation Made From (e.g: TGP, Location)</Form.Label>
                                <CoordinateInput
                                    bullseye={event?.bullseye_loc}
                                    updateFormState={updateIntelFormState}
                                    value={intelFormState.observed_from || ""}
                                    required name="observed_from" />
                            </Form.Group>
                            <Form.Group as={Col} className="col-3">
                                <Form.Label style={{"width": "100%"}}>
                                    Alt (ft)
                                    <span style={{float:"right"}}></span>
                                </Form.Label>
                                <Form.Control
                                    onChange={updateIntelFormEntry}
                                    value={intelFormState.observed_from_alt || ""}
                                    type="number" step="1" min="0" name="observed_from_alt" />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label style={{"width": "100%"}}>
                                    Observed Location

                                    <OverlayTrigger placement="right" overlay={
                                          <Popover id="popover-basic">
                                          <Popover.Header as="h3">Coordinate Format</Popover.Header>
                                          <Popover.Body>
                                            Bullseye<br/>
                                            &nbsp;&nbsp;BE 124 / 12 (spacing and / optional)<br/><br/>

                                            Decimal Degrees<br/>
                                            &nbsp;&nbsp;12.1234 24.1234<br/><br/>

                                            Degrees Minutes Seconds<br/>
                                            &nbsp;&nbsp;N 12 12 12.24 E 24 24 12.124<br/><br/>

                                            Degrees Decimal Minutes<br/>
                                            &nbsp;&nbsp;N 12 12.24 E 24 24.124<br/><br/>

                                            Various formats of the above also work (e.g: degrees symbol, ' and " etc.)
                                          </Popover.Body>
                                        </Popover>
                                        }>
                                        <span style={{
                                                position: "relative",
                                                float: "right",
                                                borderRadius: '50%',
                                                border: '1px solid black',
                                                width: "18px",
                                                height: "18px",
                                                top: "3px",
                                        }}>
                                            <span style={{
                                                position: "relative",
                                                padding: 0,
                                                fontSize: "12px",
                                                fontWeight: "bold",
                                                top: "-4px",
                                                left: "4px",
                                            }} className="">?</span>
                                        </span>
                                    </OverlayTrigger>

                                </Form.Label>
                                <CoordinateInput
                                    bullseye={event?.bullseye_loc}
                                    updateFormState={updateIntelFormState}
                                    value={intelFormState.observed_target || ""}
                                    required name="observed_target" invalidContent={"Not valid coordinates: will not be included in CF export"} />
                            </Form.Group>
                            <Form.Group as={Col} className="col-3">
                                <Form.Label style={{"width": "100%"}}>
                                    Alt (ft)
                                    <span style={{float:"right"}}></span>
                                </Form.Label>
                                <Form.Control
                                    bullseye={event?.bullseye_loc}
                                    onChange={updateIntelFormEntry}
                                    value={intelFormState.observed_target_alt || ""}
                                    type="number" step="1" min="0" name="observed_target_alt" />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Report Content</Form.Label>
                                <Form.Control
                                    onChange={updateIntelFormEntry}
                                    value={intelFormState.content || ""}
                                    required as="textarea" rows={5} name="content" />
                            </Form.Group>
                        </Row>
                        </div>
                        </Fade>
                    </div>
                </Form>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={() => { onClose(); } }>
                Close
            </Button>
            <Button variant="primary" type="submit" form="addEventForm">
                {intel ? 'Update' : 'Add'} Intel
            </Button>
            </Modal.Footer>
        </Modal>
        
    )

}

export default IntelEditModal;