const prod = {
    url: {
        API_BASE: "/api"
    }
}

const dev = {
    url: {
        API_BASE: "http://localhost:8000"
    }
}

export const config = process.env.NODE_ENV === "development" ? dev : prod;