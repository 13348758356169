import { Button } from "react-bootstrap"

export default function Home() {

    return (
        <div>
            <h1 tabIndex="-1">Welcome to Campaign Manager!</h1>

            <div className="mb-3">
            This is a work-in-progress application and your feedback is greatly appreciated! Please provide any comments, suggestions for improvement or bugs to MartinCo in the 132nd Discord.
            </div>

            <h3>Adding Intelligence</h3>    
            <div>
            <ul>
                    <li>Select Add Intelligence</li>
                    <li>Click the row, or the &nbsp;
                        <Button disabled variant="secondary" size="sm" style={{paddingTop:"0px", paddingLeft: "6px", paddingRight: "6px", paddingBottom: "2px"}}>
                            <span style={{color: "#eee", fontSize: "9px", margin: "0"}} className="oi oi-menu"></span>
                        </Button> (Show Events) button on the campaign you wish to add intelligence for
                    </li>
                    <li>Click the row, or "Add Intelligence" button next to the event for which you wish to add intel</li>
                </ul>
            
            </div>

            <h3>View Intelligence</h3>
            <div>
            <ul>
                    <li>Here you can list the intelligence for a given Campaign</li>
                    <li>Filter by event day</li>
                    <li>Download a Combat Flite file containing markers for the provided intel</li>
                </ul>
            
            </div>
        </div>
    );
}
