import { useCallback, useMemo } from "react";
import { Table } from "react-bootstrap";
import { useTable } from "react-table";
import CampaignTableButtons from "./CampaignTableButtons";

export default function CampaignTable({
    campaigns,
    setCampaignToShow,
    setShowAddEventDialog,
    ...rest
}) {
    
    const campaignTableButtonHandler = useCallback((key, campaign) => {
        let campaign_id = campaign.id;
        if (key === "list") {
            setCampaignToShow(campaign_id);
        } else if (key === "add") {
            setShowAddEventDialog({
                show: true,
                campaign: campaign,
            })
        }
    }, [setCampaignToShow, setShowAddEventDialog]);
    
    const columns = useMemo(() => {
        return [
            {
                'Header': 'Tag',
                'accessor': 'tag',
                'width': '80px',
            },
            {
                'Header': 'Maintainer',
                'accessor': 'owner',
                'width': '150px',
            },
            {
                'Header': 'Name',
                'accessor': 'name',
                'width': null,
            },
            {
                'id': 'buttons',
                'Cell': CampaignTableButtons,
                'width': '80px',
                'getProps': {
                    'handleButton': campaignTableButtonHandler,
                }
            },
        ];
    }, [campaignTableButtonHandler]);
    
    const { getTableProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: campaigns,
    })
    
    return (
        <Table striped {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps({style: {minWidth: column.minWidth || column.width, width: column.width}})} >
                        {column.render('Header')}
                    </th>
                    ))}
                </tr>
                ))}
            </thead>
            <tbody>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()} onClick={() => {campaignTableButtonHandler("list", row.original)}}>
                        {row.cells.map(cell => {
                            return (
                                <td {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                </td>
                            )
                        })}
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}