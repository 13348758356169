
import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import "./SidebarNavItem.css";

function SidebarNavItem ({icon, to, disabled, ...rest}) {

    return (
        <Nav.Link as={NavLink} to={to} {...rest}>
            <span className={"nav-link-icon oi " + icon} /> {rest.children}
        </Nav.Link>
        
    )

}

export default SidebarNavItem;