import { useCallback, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { AxiosContext } from "../../contexts/AxiosContext";
import { CampaignContext } from "../../contexts/CamapaignContext";
import IntelTable from "./IntelTable";

export default function Intel() {

    // Router Parameters
    const { campaign_id, event_id } = useParams();

    const axiosContext = useContext(AxiosContext)
    const campaignsContext = useContext(CampaignContext);

    // Local state for campaign data 
    const [campaignData, setCampaignData] = useState({});
    
    // And local event selected
    const [campaignEvent, setCampaignEvent] = useState(parseInt(event_id) || "");

    const setCampaignSelected = useCallback((campaign_id, event_id) => {

        if (!campaign_id) return;

        axiosContext.get(
            `/campaigns/${campaign_id}`,
        ).then((res) => {
            if (!res) return;
            let path =  `/intelligence/${campaign_id}`;
            let valid_event = false;

            // If we have an event_id selected, and it exists
            // use it, otherwise, come back to the event
            if (event_id) {
                let event_exists = (() => {
                    for (const evt of res.data.events) {
                        if (evt.id === event_id) return true;
                    }
                    return false
                })();
                
                if (event_exists) {
                    path += `/${event_id}`;
                    valid_event = true;                   
                }
            }
            
            if (valid_event === false) {
                setCampaignEvent("")
            }

            if (window.history.pathname !== path) {
                window.history.pushState(null, null, path);
            }
            
            setCampaignData(res.data);
        });
    }, [axiosContext])

    // Drop Down Helper
    function onCampaignSelected(event) {
        setCampaignSelected(parseInt(event.target.value) || null);
    }

    function onCampaignEventSelected(event) {
        let evt = parseInt(event.target.value) || null;
        let path =  `/intelligence/${campaignData.id}`;
        if (evt) path += `/${evt}`;
        if (window.history.pathname !== path) {
            window.history.pushState(null, null, path);
        }
        setCampaignEvent(evt)
    };

    // Load initial data based on url handling
    useEffect(() => {
        setCampaignSelected(parseInt(campaign_id) || null, parseInt(event_id) || null)
    }, [campaign_id, event_id, setCampaignSelected])

    return(
        <>
        <Form.Group as={Row} className="mb-3" >
            <Form.Label column style={{flex: '0 0 100px'}}>
                Campaign
            </Form.Label>
            <Col>
                <Form.Select onChange={onCampaignSelected} value={campaignData.id || ""}>
                    <option value="" disabled style={{color:"darkgrey"}}>Select Campaign...</option>
                    {campaignsContext.campaigns.map((x) => {
                        return <option key={x.id} value={x.id}>{x.tag}: {x.name}</option>
                    })}
                </Form.Select>
            </Col>
        </Form.Group>
        {campaignData.events && 
            <>
            <Form.Group as={Row} className="mb-3" >
            <Form.Label column style={{flex: '0 0 100px'}}>
                    Event
                </Form.Label>
                <Col>
                    <Form.Select onChange={onCampaignEventSelected} value={campaignEvent || ""}>
                        <option value="">-</option>
                        {campaignData.events.map((x) => {
                            return <option key={x.id} value={x.id}>D{x.ato_day}.{x.ato_event}</option>
                        })}
                    </Form.Select>
                </Col>
            </Form.Group>
            <hr/>
            <IntelTable campaign_data={campaignData} campaign_event={campaignEvent || ""} />
            </>
        }
        </>
    )

}