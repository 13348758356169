import { createRef, useCallback, useEffect, useState } from "react";
import { Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { coordinateDisplayFormat, coordinatesFromString } from "../utils";

export default function CoordinateInput({
    name,
    value: originalValue,
    strict,
    bullseye,
    updateFormState,
    invalidContent,
    ...rest
}) {

    invalidContent = invalidContent || "Not valid coordinates"

    const [coordinate, setCoordinate] = useState({'valid': false});
    const [value, setValue] = useState();
    const inputRef = createRef()

    const update_value = useCallback((update_value) => {

        if (value === update_value) { return; }
        setValue(update_value);

        let dd = coordinatesFromString(update_value, bullseye)

        // Update our parent
        updateFormState({
            [name]: update_value,
            [name+"_loc"]: dd ? {
                lat: Number(dd[0].toFixed(7)),
                lon: Number(dd[1].toFixed(7)),
            } : null,
        })

        // If we're strict, then we need both lat and lon to be set and invalidate the form if so
        if (strict && (!dd || !dd[0] || !dd[1])) {
            inputRef.current.setCustomValidity("Requires a valid coordinate")
        } else {
            inputRef.current.setCustomValidity("")
        }

        // General behaviour now to show the globe state
        if (!dd) {
            setCoordinate({valid: false});
            return
        }

        setCoordinate({
            valid: true,
            fmt: coordinateDisplayFormat(dd)
        })

    }, [inputRef, strict, value, name, bullseye, updateFormState]);

    const onChange = (evt) => {
        update_value(evt.target.value);
    }

    useEffect(() => {
        update_value(originalValue)
    }, [originalValue, update_value])

    let tooltip = coordinate.valid ? (
            <div>
            Valid coordinates
            <br/>
                {coordinate.fmt[0]} {coordinate.fmt[1]}
            </div>
        ) : (
            <div>
                {invalidContent}
            </div>
        );

    return (
        <InputGroup>
            <Form.Control {...rest} ref={inputRef} name={name} onChange={onChange} value={value || ""} />
            <InputGroup.Text style={{width: "44px"}}>
                <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
                    <span style={{marginLeft: "auto", marginRight: "auto", color: coordinate.valid ? "green" : "darkred"}} className="oi oi-globe"/>
                </OverlayTrigger>
            </InputGroup.Text>
        </InputGroup>
    )
}
