import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

export default function CampaignTableButtons({
    cell,
}) {
    
    const handleAddList = (e) => {
        e.target.blur();
        cell.column.getProps.handleButton("add", cell.row.original);
    }

    const handleClickList = (e) => {
        e.target.blur();
        cell.column.getProps.handleButton("list", cell.row.original);
    }

    return (
        <div>
        <OverlayTrigger
            overlay={
                <Tooltip>
                    Add new ATO
                </Tooltip>
            } 
            placement="left">
            <Button onClick={handleAddList} variant="secondary" size="sm" style={{paddingTop:"0px", paddingLeft: "6px", paddingRight: "6px", marginRight:"10px", paddingBottom: "2px"}}>
                <span style={{color: "#eee", fontSize: "9px", margin: "0"}} className="oi oi-plus"></span>
            </Button>
        </OverlayTrigger>
        <OverlayTrigger
            overlay={
                <Tooltip>
                    Show Events
                </Tooltip>
            } 
            placement="left">
            <Button onClick={handleClickList} variant="secondary" size="sm" style={{paddingTop:"0px", paddingLeft: "6px", paddingRight: "6px", paddingBottom: "2px"}}>
                <span style={{color: "#eee", fontSize: "9px", margin: "0"}} className="oi oi-menu"></span>
            </Button>
               
        </OverlayTrigger>
        </div>
    )

}