import { useState } from "react";
import { Form } from "react-bootstrap";
import { get_seconds_from_time, get_time_from_seconds } from "../utils";


function TimeInput({
    name,
    onChange: parentOnChange,
    value: initial_value,
    ...rest
}) {

    const [state, setState] = useState(initial_value || "");

    // Make sure it's a valid 24 hour time HH:MM
    const onChange = (evt) => {
        let value = evt.target.value;
        if (!value.match(/^([0-9]{0,2}:?[0-9]{0,2})?$/)) return;
        setState(value);
    }

    const onBlur = (evt) => {
        let new_value=get_time_from_seconds(get_seconds_from_time(evt.target.value))

        // Update the target, then forward the eevent up so we can just read the DOM
        evt.target.value = new_value

        parentOnChange(evt);
        setState(new_value)
    }

    return (
        <Form.Control {...rest} style={{textAlign: "center"}} value={state} onChange={onChange} onBlur={onBlur} name={name} />
    )

}

export default TimeInput